import React from 'react'
import img from '../assets/images/nousIntervenons/Rectangle355.png';
import phone from '../assets/svg/Group1.svg';
import '../style/intervensionRapide.css'

const InterventionRapide = () => {
  return (
    <div className='intervension-rapide flex-column flex-lg-row'>
      <img src={img} alt="" />
      <div className='content mt-5 mt-lg-auto mb-lg-auto'>
        <div className='text ms-md-5'>
          <p>Nous sommes là pour vous !</p>
          <p>intervention rapide <span>dès 99 dT</span></p>
        </div>
        <div className='d-flex flex-row align-items-center '>
          <img src={phone} alt="" className='ms-md-3' />
          <p className='d-flex flex-column  ms-2'>
            <span className='devis'>Devis par téléphone </span>
            <a href="tel:50811811"><span className='phone-nbr'> 50 811 811</span></a></p>
        </div>
      </div>
    </div>
  )
}

export default InterventionRapide