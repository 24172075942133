import React from 'react';
import logo from '../assets/images/kooba-blanc 2.png';
import phoneIcon from '../assets/svg/phone_icon_orange.svg';

import '../style/footer.css';

const Footer = () => {
  return (
    <footer className='d-flex flex-column align-items-center justify-content-center'>
      <img src={logo} alt="" className='footer-logo' />
      <div className='footer-phone d-none d-lg-flex flex-row'>
        <img src={phoneIcon} alt="" />
        <a href='tel:55811811'><p> 50 811 811</p></a>
      </div>
      <p className='text-center'>Dépannage de serrurerie 24h/24 Pour tout type d’intervention: porte claquée, clés perdues ou cassées dans la serrure, changement de serrure.</p>
      <div className='copyright'></div>



    </footer>
  )
}

export default Footer