import React from 'react';
import '../style/interventionCard.css'

const InterventionCard = (props) => {
  const Src = props.Src;
  const imgSrc = props.imgSrc;
  const title = props.title;
  const description = props.description;
  return (
    <div className='intervention-card d-flex flex-column align-items-center justify-content-center'>
      <div className='w-100' >
        <img src={Src} alt="" className='w-100' />
      </div>
      <div className='card-img d-flex align-items-center justify-content-center' >
        <img src={imgSrc} alt="" />
      </div>
      <h3 className='card-title'>
        {title}
      </h3>
      <p className='card-description mx-3'>
        {description}
      </p>
    </div>
  )
}

export default InterventionCard