import logo from '../assets/images/kooba-blanc 2.png'
import phone_icon from '../assets/svg/phone_icon.svg'
import phone_icon_orange from '../assets/svg/phone_icon_orange.svg'
import whatsapp from '../assets/svg/Group.svg'
import '../style/navbar.css'

function Navbar() {
  return (
    <nav className="navbar d-flex flex-row">
      <img src={logo} className="App-logo" alt="logo" />

      <div className='phone d-flex flex-row justify-content-center align-items-center' role='button'>
        <img src={phone_icon} alt="phone" className='d-none d-lg-flex' />
        <div className='d-flex d-lg-none phone-icon me-1'>
          <img src={phone_icon_orange} alt="phone" className='d-flex d-lg-none phone-icon' />
        </div>
        <a href='tel:55811811'><p> 50 811 811</p></a>
      </div>

      <span role='button' className='btn-whatsapp d-none d-md-flex'>
        <a href="https://api.whatsapp.com/send?phone=50811811" target='_blank' rel="noreferrer"><img src={whatsapp} alt="" /></a>
      </span>
    </nav>
  )
}
export default Navbar;
