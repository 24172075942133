import { useEffect, useState } from 'react';
import Interventions from './components/24heursIntervention';
import CallUs from './components/CallUs';
import Footer from './components/Footer';
import Hero from './components/Hero';
import Navbar from './components/Navbar';
import NousIntervenons from './components/NousIntervenons';
import InterventionRapide from './components/intervensionRapide';

function App() {
  const [isFixed, setIsFixed] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const footer = document.querySelector('footer');
      const div = document.querySelector('.call_us_buttons');

      if (footer && div) {
        const footerTop = footer.getBoundingClientRect().top;
        const divTop = div.getBoundingClientRect().top;
        const scrollTop = window.pageYOffset || document.documentElement.scrollTop;

        if (footerTop < window.innerHeight && footerTop > divTop) {
          setIsFixed(false);
        } else if (scrollTop === 0) {
          setIsFixed(false);
        } else {
          setIsFixed(true);
        }
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  return (
    <>
      <Navbar />
      <Hero />
      <NousIntervenons />
      <InterventionRapide />
      <Interventions />
      <CallUs position={isFixed ? 'fixed' : ''} />
      <Footer />
    </>
  );
}

export default App;
