import React from 'react';
import image1 from '../assets/images/24hoursInterventions/image-4.png';
import image2 from '../assets/images/24hoursInterventions/image-5.png';
import image3 from '../assets/images/24hoursInterventions/image-6.png';
import tick from '../assets/svg/Vector.svg'
import '../style/24hoursIntervention.css'

const Interventions = () => {
  return (
    <section className='Interventions-section d-flex flex-column-reverse flex-lg-row'>

      <div className='img-block d-flex flex-row justify-content-center justify-content-lg-start gap-2 me-2'>
        <div className='d-flex flex-column justify-content-end gap-3 mt-4'>
          <img src={image1} alt="image2" />
          <img src={image3} alt="image1" />
        </div>
        <div className='d-flex flex-column justify-content-end'>
          <img src={image2} alt="image3" />
        </div>
      </div>

      <div className='Interventions-content'>
        <span className='Interventions-surtitle mt-5'> </span>
        <h3 className='Interventions-title'>
          Nous intervenons 24h/24, 7J/7.
        </h3>
        <p className='Interventions-description'>
          dépannage de serrurerie d ‘urgence par des experts qualifiés, notre équipe intervient rapidement pour vous apporter des solutions sur mesure.
        </p>

        <ul className='quality-list'>
          <li className='quality d-flex flex-row align-items-center mb-2'>
            <img src={tick} alt="" />
            <p className='m-0 ms-3'>Sérrurier jour et nuit</p>
          </li>
          <li className='quality d-flex flex-row align-items-center mb-2'>
            <img src={tick} alt="" />
            <p className='m-0 ms-3'>Déplacement rapide</p>
          </li>
          <li className='quality d-flex flex-row align-items-center mb-2'>
            <img src={tick} alt="" />
            <p className='m-0 ms-3'>Dépannage en urgence</p>
          </li>
        </ul>
        <button className='btn call-us d-none d-lg-flex'><a href="tel:55811811">Appelez-nous</a></button>
      </div>
    </section>
  )
}

export default Interventions