import React from 'react';
import image1 from '../assets/images/hero/image_1.png'
import image2 from '../assets/images/hero/image_2.png'
import image3 from '../assets/images/hero/image_3.png'
import image4 from '../assets/images/hero/image_4.png'
import '../style/hero.css'

const Hero = () => {
  return (
    <section className='hero-section d-flex flex-column flex-lg-row'>
      <div className='hero-content'>
        <span className='hero-surtitle mt-md-5'> </span>
        <h1 className='hero-title'>
          Votre expert en dépannage de serrurerie
        </h1>
        <p className='hero-description'>
          A votre service 24H /24H pour assurer votre sécurité et tranquilité
        </p>
        <button className='btn call-us d-none d-lg-flex'><a href="tel:55811811">Appelez-nous</a></button>
      </div>
      <div className='img-block d-flex flex-row justify-content-center justify-content-lg-end gap-2 gap-lg-3'>
        <div className='d-flex flex-column gap-2 gap-lg-3 mt-4'>
          <img src={image3} alt="image1" />
          <img src={image1} alt="image2" />
        </div>
        <div className='d-flex flex-column gap-2 gap-lg-3'>
          <img src={image2} alt="image3" />
          <img src={image4} alt="image4" />
        </div>
      </div>
    </section>
  )
}

export default Hero