import React from 'react'
import mobile from '../assets/svg/phone_icon-mobile.svg';
import whatsappMobile from '../assets/svg/whatsapp-mobile.svg';
import '../style/callUs.css'

const CallUs = (props) => {

  return (
    <div className={`call_us_buttons d-flex flex-row d-lg-none w-100 ${props.position}`} >
      <div role='button' className='tel w-50 d-flex flex-row justify-content-center align-items-center'>
        <a href='tel:55811811' className='tel w-50 d-flex flex-row justify-content-center align-items-center'>
          <img src={mobile} alt="" />
          <div className='d-flex flex-column ms-2'>
            <p>appelez-nous sur</p>
            <span>téléphone</span>
          </div>
        </a>
      </div>
      <div role='button' className='whatsapp w-50 d-flex flex-row justify-content-center align-items-center'>
        <a href="https://api.whatsapp.com/send?phone=50811811" target='_blank' rel="noreferrer" className='whatsapp w-50 d-flex flex-row justify-content-center align-items-center'>
          <img src={whatsappMobile} alt="" />
          <div className='d-flex flex-column ms-2'>
            <p>appelez-nous sur</p>
            <span>whatsapp</span>
          </div>
        </a>
      </div>
    </div>
  )
}

export default CallUs