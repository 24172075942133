import React from 'react'
import InterventionCard from './InterventionCard';
import src1 from '../assets/images/interventions/PORTE.png';
import src2 from '../assets/images/interventions/VOITURE.png';
import src3 from '../assets/images/interventions/CLE.png';
import img from '../assets/images/nousIntervenons/Rectangle12.png';
import img2 from '../assets/images/nousIntervenons/Rectangle13.png';
import img3 from '../assets/images/nousIntervenons/Rectangle14.png';
import '../style/nousIntervenons.css';

const NousIntervenons = () => {
  return (
    <section className='interventions d-flex flex-column justify-content-center align-items-center'>
      <span className='hero-surtitle mt-md-5 d-flex d-lg-none'> </span>
      <h2 className='section-title'>
        Nous intervenons
      </h2>
      <div className='cards d-flex flex-column flex-lg-row justify-content-between align-items-center gap-3'>
        <InterventionCard imgSrc={src2} Src={img} title="Dépannage D’urgence" description="Réponse rapide aux problèmes de verrouillage urgents." />
        <InterventionCard imgSrc={src1} Src={img3} title="Ouverture Porte" description="Accès rapide et fiable lorsque vous êtes bloqué." />
        <InterventionCard imgSrc={src3} Src={img2} title="Changement De Serrure" description="Remplacement rapide des serrures anciennes ou endommagées." />
      </div>
    </section>
  )
}

export default NousIntervenons